import React, {useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Input, Label, FormGroup, SingleActionModal, Form, Col, Row} from '../../components/bootstrap';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IOrganization} from '../../models/Organization';
import {User, UserType} from '../../models/User';
import {translateError} from '../../utils/Errors';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {useObjectState} from '../../utils/ObjectState';
import {validateRequired, validateEmail, validateAtLeast, validateUsername} from '../../utils/Validation';
import {useUser} from '../CardUtils';

interface EditUserProps extends IPromiseModalProps<User | undefined> {
  user: User;
}

const EditUser = (props: EditUserProps) => {
  const {user} = props;

  const form = useFormState();
  const [state, updateFormState] = useObjectState({...user});
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const me = useUser();
  const isServiceDesk = me.isServiceDesk();
  const availableRoles = isServiceDesk
    ? User.ROLES.filter(role => role.available)
    : User.ROLES.filter(role => role.available && !role.helpdeskOnly);
  const userTypeOptions = availableRoles.map(role => (
    <option key={role.value} value={role.value}>
      {T(role.label)}
    </option>
  ));
  const [alertMessage, setAlertMessage] = useState<string>();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState<IOrganization>();
  const [loading, setLoading] = useState(false);
  const type = state.role;

  const handleUserTypeChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    updateFormState({role: e.currentTarget.value as UserType});
  };

  const handleClickedSave = async () => {
    if (form.hasErrors()) {
      form.showErrors();
      return '';
    }

    const newUser = user.isNewUser();

    const userName = state.userName ?? '';
    const firstName = state.firstName ?? '';
    const lastName = state.lastName ?? '';
    const email = state.email ?? '';
    const password = state.password ?? '';
    const emailAddressVerified = state.emailAddressVerified ?? false;

    const updatedUser = user.clone();
    updatedUser.firstName = firstName;
    updatedUser.lastName = lastName;
    updatedUser.userName = userName;
    updatedUser.email = email;
    updatedUser.role = type;
    updatedUser.emailAddressVerified = emailAddressVerified;

    // Only change password deliberately
    if (newUser && password && password !== '') {
      updatedUser.password = password;
    }

    setAlertMessage(undefined);
    setLoading(true);

    // Create or update user
    const promise = newUser
      ? api.createUser({
          userName,
          firstName,
          lastName,
          emailAddress: email,
          type,
          country: '',
          password,
          organization: type === UserType.ServiceDesk ? undefined : organization && organization.id
        })
      : api.updateUser(user.id!, {
          userName,
          firstName,
          lastName,
          emailAddress: email,
          type,
          emailAddressVerified
        });
    promise
      .then(() => {
        resolve(updatedUser);
        NotificationManager.success(newUser ? T('users.created') : T('users.updated'));
      })
      .catch(error => {
        if (error.code === 'email.exists') {
          setAlertMessage(T('errors.email.exists', {email}));
          return form.setServerError('email', T('errors.email.exists', {email}));
        }
        if (error.code === 'username.exists') {
          setAlertMessage(T('users.error.alreadyInUse', {userName}));
          return form.setServerError('userName', T('users.error.alreadyInUse', {userName}));
        }

        return setAlertMessage(translateError(error, T('users.error.couldNotSave'), {userName}));
      })
      .finally(() => setLoading(false));
  };

  const isNew = user.isNewUser();
  const isPartnerRole =
    type === UserType.PartnerAdmin ||
    type === UserType.PartnerViewer ||
    type === UserType.PartnerReadOnly ||
    type === UserType.PartnerAPI;
  const isRegularRole = type === UserType.BasicAdmin || type === UserType.BasicReadOnly;

  const mainForm = (
    <>
      <TextInputGroup
        form={form}
        name="userName"
        autoFocus
        label={T('profile.username')}
        value={state.userName}
        onChange={userName => updateFormState({userName})}
        validate={validateUsername}
        disabled={state.emailAddressVerified}
      />
      <TextInputGroup
        form={form}
        name="firstName"
        label={T('profile.firstName')}
        value={state.firstName}
        onChange={firstName => updateFormState({firstName})}
        validate={validateRequired}
      />
      <TextInputGroup
        form={form}
        name="lastName"
        label={T('profile.lastName')}
        value={state.lastName || ''}
        onChange={lastName => updateFormState({lastName})}
        validate={validateRequired}
      />
      <TextInputGroup
        form={form}
        name="email"
        label={T('profile.email')}
        value={state.email}
        onChange={email => updateFormState({email})}
        validate={validateEmail}
        disabled={state.emailAddressVerified}
      />
      {isNew && (
        <TextInputGroup
          form={form}
          name="password"
          label={T('login.password')}
          value={state.password ?? ''}
          onChange={password => updateFormState({password})}
          validate={user.isNewUser() ? validateAtLeast(8) : undefined}
          type="password"
        />
      )}

      {isNew && (
        <FormGroup>
          <Label>{T('users.edit.type')}</Label>
          <Input name="type" type="select" value={type} onChange={handleUserTypeChanged}>
            {userTypeOptions}
          </Input>
        </FormGroup>
      )}
      {isServiceDesk && !isNew && (
        <Checkbox
          id="serviceDesk"
          name="serviceDesk"
          label={T('role.serviceDesk')}
          checked={state.role === UserType.ServiceDesk}
          onCheckedChange={checked => updateFormState({role: checked ? UserType.ServiceDesk : UserType.BasicAdmin})}
          testId="serviceDesk"
        />
      )}
      {isServiceDesk && !isNew && (
        <Checkbox
          id="emailAddressVerified"
          name="emailAddressVerified"
          label={T('users.edit.emailVerified')}
          checked={state.emailAddressVerified || false}
          onCheckedChange={emailAddressVerified => updateFormState({emailAddressVerified})}
          testId="emailAddressVerified"
        />
      )}
    </>
  );

  const renderedForm = isNew ? (
    <Row>
      <Col md={6}>{mainForm}</Col>
      <Col md={6}>
        {type !== UserType.ServiceDesk && (
          <OrganizationInputGroup
            label={T('locations.moveToOrganization.organization')}
            organizations={inputOrganizations.organizations}
            name="organization"
            value={organization}
            onChange={setOrganization}
            onUpdateQuery={updateOrganizationInputQuery}
          />
        )}
        {isPartnerRole && <span style={{color: 'gray'}}>{T('users.edit.info.partner')}</span>}
        {isRegularRole && <span style={{color: 'gray'}}>{T('users.edit.info.regular')}</span>}
        {/*hasFeature(AppFeature.ImprovedUserAccess) && (
          <SelectInputGroup label={T('users.edit.role')} name="role" value={role} onChange={setRole}>
            {roles.map(role => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </SelectInputGroup>
            ) -- postpone this until later*/}
      </Col>
    </Row>
  ) : (
    mainForm
  );

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(undefined)}
      title={isNew ? T('users.edit.title.create') : T('users.edit.title.update')}
      action={handleClickedSave}
      actionText={isNew ? T('users.edit.confirm.create') : T('users.edit.confirm.update')}
      error={alertMessage}
      loading={loading}
      size={isNew ? 'lg' : 'md'}
    >
      <Form>
        <FormContextProvider value={form}>{renderedForm}</FormContextProvider>
      </Form>
    </SingleActionModal>
  );
};

export default EditUser;
